<template>
   <div class="modalPolitica">
    <div class="modalPolitica__container">
      <perfect-scrollbar ref="scroll">
        <h1>REGULAMENTO DO PROGRAMA TEC CLUB</h1>
        <div>
          <div class="modalPolitica__politica">
            <p>O Programa TEC CLUB+ (<b>“Programa”</b>) é um programa de relacionamento, capacitação e reconhecimento dos técnicos, administrativos e das Assistências Técnicas Autorizadas, representadas por seus titulares/gestores, das marcas BRASTEMP, CONSUL, KITCHENAID e B.BLEND, sendo mantido sob a responsabilidade da <b>Whirlpool S.A.</b>, pessoa jurídica de direito privado com sede na Rua Olympia Semeraro, 675, Prédio Administrativo, Jardim Santa Emília, São Paulo, SP, inscrita no CNPJ sob o nº 59.105.999/0001-86 (<b>“Whirlpool”</b>).</p>
                <p>Como já mencionado acima, o Programa é destinado exclusivamente aos técnicos, administrativos e titulares/gestores das Assistências Técnicas Autorizadas das marcas BRASTEMP, CONSUL, KITCHENAID e B.BLEND.</p>

                <p><b>1. DOS ELEGÍVEIS A  PARTICIPAÇÃO </b></p>
                <p>
                1.1 Estão elegíveis a participar do Programa apenas e tão somente os empregados das Assistências Técnicas Autorizadas que possuam um relacionamento comercial com a Whirlpool, sendo, de forma mais específica, os empregados com cargo de técnico ou auxiliar técnico; assistente administrativo ou auxiliar administrativo; coordenador ou gerente; titular; maiores de 18 anos, que estejam diretamente envolvidos com os atendimentos técnicos dos produtos das marcas BRASTEMP, CONSUL, KITCHENAID e B.BLEND, inclusive atendendo às Ordens de Serviços (OS) das marcas já mencionadas acima, e que tiverem seus dados vinculados à OS no sistema de <i>Customer Relationship Management(CRM)</i> da Whirlpool, Sankhya(Sistema) ou Vuupt (OS Digital) (<b>“Participante”</b>).
                </p>
                <p>1.1.1 Para fins de esclarecimento, os empregados das Assistências Técnicas Autorizadas da Whirlpool que venham a participar do Programa não possuem e não possuirão qualquer vínculo trabalhista com essa empresa.</p>
                <p>1.2 A Assistência Técnica Autorizada será responsável por informar à Whirlpool os nomes dos seus empregados elegíveis a participar do Programa, número do CPF e cargo, conforme mecanismos e demais disposições descritas neste instrumento. Em outras palavras, o participante que não tiver seus dados inseridos no sistema do Programa pela Assistência Técnica Autorizada empregadora não poderá participar do Programa. </p>

            </div>
        </div>
        <div style="page-break-before:always; page-break-after:always">
            <div>
                <p>1.3 A Assistência Técnica Autorizada é a única e exclusiva responsável por obter todas as autorizações necessárias dos seus empregados elegíveis para passar à Whirlpool as informações requeridas no item 1.2 acima.</p>

                <p><b>2. DO CADASTRO DOS PARTICIPANTES </b></p>
                <p>2.1 Cada empregado da Assistência Técnica Autorizada somente poderá ser cadastrado para um único cargo, sendo que, em nenhuma hipótese, poderá o empregado da Assistência Técnica Autorizada ter mais de um cargo cadastrado no Programa, do contrário a Whirlpool poderá excluí-lo do Programa.</p>
                <p>2.2 Caso o empregado da Assistência Técnica Autorizada seja elegível para participar do Programa, deverá, como condição de participação, realizar o seu cadastro no site (https://programatecclub.com.br) (<b>“Site”</b>), complementar o seu cadastro informando os dados solicitados, criar uma senha, bem como aceitar os termos e condições do Programa previstos neste Regulamento.</p>
                <p>2.2.1 É de inteira responsabilidade do Participante o preenchimento de todos os dados de maneira correta e acurada. Caso haja o fornecimento de dados incorretos e/ou incompletos, o Participante não será considerado como cadastrado neste Programa. Da mesma forma, caso o Participante perceba qualquer erro cadastral, deverá comunicar à Whirlpool imediatamente por meio da Central de Atendimento ao Participante (suporte@programatecclub.com.br), através do telefone e/ou WhatsApp <b>0800 236 0011</b>.</p>
                <p>2.2.2 Ao aceitar estes termos e condições, o Participante autoriza a Whirlpool a lançar seu nome e foto no Ranking e divulgá-lo no Site, e comunicações e eventos internos.</p>
                <p>2.3 A participação no Programa somente valerá a partir da realização do cadastro no Site e confirmação, pela Whirlpool, de que o cadastro foi realizado com sucesso. A pontuação valerá dentro do mesmo mês de cadastro no caso de o participante finalizar o cadastro dentro da data de corte (dia 15 de cada mês). Cadastro realizado posteriormente à data de corte, a pontuação será válida apenas no mês subsequente.</p>
                <p>2.4 O Participante será automaticamente excluído do Programa em caso de rescisão do contrato ou desligamento da Assistência Técnica Autorizada empregadora a qual estava vinculado, independentemente do motivo.</p>
                <p>2.4.1 Em caso de rescisão contratual ou desligamento da Assistência Técnica Autorizada pela empregadora durante o prazo de vigência do Programa, o Participante terá o prazo improrrogável para resgate dos pontos acumulados de até 90 (noventa) dias após a inativação da Assistência Técnica Autorizada empregadora. Se, após a inativação do usuário no Programa, o Participante tornar-se elegível novamente, em decorrência da contratação por outra Assistência Técnica Autorizada, deverá providenciar um novo cadastro. Eventual saldo de pontos acumulados anteriormente não será transferido e deverá ser resgatado no prazo estabelecido anteriormente e, após esse prazo, os pontos expirarão sem possibilidade de reversão.</p>

            </div>
        </div>
        <div style="page-break-before:always; page-break-after:always">
            <div>
                <p>2.5 Os Participantes que forem admitidos e/ou cadastrados pela Assistência Técnica Autorizada após o início do Programa terão seus pontos computados somente a partir do 1° dia do mês seguinte, nos casos em que a contratação/cadastro tenham ocorrido até o 15º(décimo quinto) dia do mês do cadastro, desde que tenham concluído seu cadastro e aceito os termos. Para os casos que a contratação/cadastro tenha ocorrido a partir do 16º(décimo sexto) dia do mês, a pontuação será válida apenas no mês subsequente. </p>
                <p>2.6 Serão automaticamente desclassificados e excluídos do Programa, sem necessidade de prévia comunicação, o Participante que praticar qualquer tipo de ato considerado fraudulento, ilegal, ilícito, ou que atente contra os objetivos deste Programa e/ou estes termos e condições, independentemente de seu ato ter causado seu desligamento da Assistência Técnica Autorizada empregadora.</p>
                <p>2.7 Não será aceito o pedido de cadastro que não preencher todos os requisitos descritos neste regulamento.</p>

                <p><b>3. DO PROGRAMA E PONTUAÇÃO</b></p>
                <p>3.1 Este Programa terá duração com as metas de 2024 a partir de 01 de março de 2024, com vigência até às 23h59 do dia 31 de dezembro de 2024. </p>
                <p>3.2 Desde que sejam atingidos os gatilhos definidos para cada Participante, esses receberão pontos, que podem ser acumulados e trocados por recompensas no Portal de Prêmios, conforme disposições no Site. Em nenhuma hipótese, haverá pagamento em dinheiro ao Participante.</p>
                <p>3.3 O Programa é baseado na distribuição de pontos. Estes começam a ser contabilizados aos Participantes a partir do mês que for realizado o cadastro de forma correta e dado o aceite nos termos e condições deste regulamento. Em nenhuma hipótese serão aceitas as metas realizadas em meses anteriores à realização do cadastro de forma correta e aceite deste instrumento.</p>

            </div>
        </div>
        <div style="page-break-before:always; page-break-after:always">
            <div>
                <p>3.4 Fica desde já estabelecido que cada ponto conquistado pelo Participante neste Programa é equivalente ao valor de R$ 1,00 (um) real.</p>
                <p><b>4. CRITÉRIOS DE AVALIAÇÃO E PONTUAÇÃO DOS PARTICIPANTES</b></p>
                <p>4.1 Os Participantes serão avaliados, de acordo com o cargo, por meio do atingimento das metas durante o período do Programa, conforme critérios abaixo</p>
                <p>4.2. Os Participantes denominados “técnicos” receberão pontos de acordo com as metas atingidas mensalmente nos indicadores “Técnico 5 Estrelas”, “FCC (First Call Completed)”, "Reincidência Técnica” e campanhas adicionais lançadas sazonalmente determinados pela Whirlpool e pela participação e avaliação nos treinamentos da “Universidade Encantamento” e da “Escola Técnica”, que podem ser acumulados e trocados por prêmios do catálogo online, acessível através do Site.</p>
                <p>EXEMPLO DE CÁLCULO:</p>
                <p>
                <table>
                    <tr>
                        <th class="criterios">CRITÉRIOS</th>
                        <th class="regras">REGRAS</th>
                        <th class="meta">METAS</th>
                        <th class="pontos">PONTOS</th>
                    </tr>
                    <tr>
                        <td><span>Técnico 5 Estrelas</span></td>
                        <td>
                            Avaliação do consumidor fornecida após o atendimento técnico via Whatsapp - escala de 1 a 5 - quanto maior, melhor.
                        </td>
                        <td><span>4,7</span></td>
                        <td><span>90</span></td>
                    </tr>
                    <tr>
                        <td><span>FCC OPS (First Call Completed)</span></td>
                        <td>
                            Avaliação do consumidor fornecida após o atendimento técnico via Whatsapp - percentual de respostas “1” - quanto maior, melhor. (métrica operacional)
                        </td>
                        <td><span>66%</span></td>
                        <td><span>70</span></td>
                    </tr>
                    <tr>
                        <td><span>Reincidência Técnica</span></td>
                        <td>Taxa média mensal de consumidores que tiveram 2 (duas) OS abertas com visita atrelada em até 30 (trinta) dias ou 1 (uma) OS aberta com 3 (três) visitas ou mais para ter seu problema solucionado.
                        </td>
                        <td><span>3,8%</span></td>
                        <td><span>40</span></td>
                    </tr>
                </table>
                </p>

                <p>4.2.1 Cada Participante deverá ter no mínimo 5 (cinco) pesquisas de satisfação respondidas no mês para ser elegível a pontuação dos indicadores “FCC (First Call Completed)” e “Técnico 5 Estrelas”, e no mínimo 1(uma) OS geral atrelada ao CPF do usuário.</p>

            </div>
        </div>
        <div style="page-break-before:always; page-break-after:always">
            <div>
                <p>4.2.2 Os Participantes receberão pontos de acordo com as metas atingidas mensalmente, conforme descrito acima.</p>
                <p>4.3 Os Participantes denominados <b>“Administrativos”</b> receberão pontos de acordo com as metas atingidas mensalmente nos indicadores “TAE/TLS”, “FCC OPS (First Call Completed)”,  "5 Stars Exp” e campanhas adicionais lançadas sazonalmente, determinados pela Whirlpool e pela participação e avaliação nos treinamentos da “Universidade Encantamento” e da “Escola Administrativa”, que podem ser acumulados e trocados por prêmios do catálogo online, acessível através do Site. </p>

                <p>EXEMPLO DE CÁLCULO MASTER:</p>
                <p>
                <table>
                    <tr>
                        <th class="criterios">CRITÉRIOS</th>
                        <th class="regras">REGRAS</th>
                        <th class="meta">METAS</th>
                        <th class="pontos">PONTOS</th>
                    </tr>
                    <tr>
                        <td><span>TLS</span></td>
                        <td>
                            Média de tempo de atendimento das OS's (ordens de serviço)
                        </td>
                        <td><span>
                                NO: 4,5<br />
                                CO: 4,5<br />
                                NE: 4,4<br />
                                SE: 3,8<br />
                                S: 3,5
                            </span></td>
                        <td><span>30</span></td>
                    </tr>
                    <tr>
                        <td><span>FCC (First Call Completed)</span></td>
                        <td>
                            Avaliação do consumidor fornecida após o atendimento técnico via Whatsapp - percentual de respostas “1” - quanto maior, melhor. (métrica global)
                        </td>
                        <td><span>66%</span></td>
                        <td><span>40</span></td>
                    </tr>
                    <tr>
                        <td><span>5 Stars Exp Ops</span></td>
                        <td>Avaliação do consumidor fornecida após o atendimento via Whatsapp - escala de 1 a 5 - quanto maior, melhor.</td>
                        <td><span>4,6</span></td>
                        <td><span>30</span></td>
                    </tr>
                </table>
                </p>

                <p>EXEMPLO DE CÁLCULO ESTRELA:</p>
                <p>
                <table>
                    <tr>
                        <th class="criterios">CRITÉRIOS</th>
                        <th class="regras">REGRAS</th>
                        <th class="meta">METAS</th>
                        <th class="pontos">PONTOS</th>
                    </tr>
                    <tr>
                        <td><span>TAE</span></td>
                        <td>Média de tempo de atendimento das OS's (ordens de serviço)</td>
                        <td><span>9</span></td>
                        <td><span>40</span></td>
                    </tr>
                    <tr>
                        <td><span>FCC (First Call Completed)</span></td>
                        <td>
                            Avaliação do consumidor fornecida após o atendimento técnico via Whatsapp - percentual de respostas “1” - quanto maior, melhor. (métrica global)
                        </td>
                        <td><span>66%</span></td>
                        <td><span>30</span></td>
                    </tr>
                    <tr>
                        <td><span>5 Stars Exp Ops</span></td>
                        <td>Avaliação do consumidor fornecida após o atendimento via Whatsapp - escala de 1 a 5 - quanto maior, melhor.</td>
                        <td><span>4,6</span></td>
                        <td><span>30</span></td>
                    </tr>
                </table>
                </p>

                <p>4.3.1 Cada Participante deverá ter no mínimo 5 (cinco) pesquisas de satisfação respondidas no mês para ser elegível a pontuação dos indicadores “FCC (First Call Completed)” e “5 Stars Exp Ops”.</p>
                <p>4.3.2 Os Participantes receberão pontos de acordo com as metas atingidas mensalmente, conforme descrito acima.</p>
                <p>4.4 Para cada tipo de treinamento, sendo “Universidade Encantamento”, “Escola Técnica(Terça Técnica)” e “Escola Administrativa(Hora do ADM)” realizado, e desde que o Participante acerte, no mínimo, 90% (noventa por cento) na primeira tentativa na avaliação de cada treinamento e realize 100%(cem por cento) dos treinamentos disponíveis no mês para seu respectivo cargo, o Participante receberá até 25 (vinte e cinco) pontos por tipo treinamento, sendo 5 (cinco) pontos por cada tema realizado. Para os demais, nenhum ponto será computado.</p>
                <p>4.4.1 A pontuação máxima por tipo de treinamento, sendo “Universidade Encantamento”, “Escola Técnica” e/ou “Escola Administrativa” será de 25 (vinte e cinco) pontos, podendo variar conforme a disponibilidade de treinamentos no mês. O máximo que o Participante poderá acumular no mês em treinamentos será de 50 (cinquenta) pontos.</p>

                <p>4.5 No mês de aniversário do Participante, este fará jus a 10 (dez) pontos.</p>

                <p>4.6 Os Participantes serão classificados mensalmente de acordo com o ranking que considerará a somatória total dos pontos atingidos nos critérios mencionados.</p>

                <p>4.6.1 Caso haja empate no <b>cargo técnico</b>, a regra de desempate no ranking seguirá a seguinte ordem: maior pontuação total; maior performance em FCC Ops; maior performance Técnico 5 estrelas; menor índice de reincidência; maior pontuação em treinamentos e, por fim, maior tempo de cadastro no Programa.</p>

                <p>4.6.2 Caso haja empate no <b>cargo administrativo</b>, a regra de desempate no ranking seguirá a seguinte ordem: maior pontuação total; maior performance em FCC Ops; maior performance 5 Stars Exp Ops; menor TAE/TLS; maior pontuação em treinamentos e, por fim, maior tempo de cadastro no Programa.</p>

                <p>4.7 As Assistências Técnicas Autorizadas, representadas pelos denominados <b>“titulares/gestores”</b>, receberão pontos de acordo com as metas atingidas mensalmente nos indicadores “TAE/TLS”, “FCC Ops (First Call Completed)”,  "5 Stars Exp Ops”, “Crescimento Volume FG (Fora de Garantia)(%)” e campanhas adicionais lançadas sazonalmente, determinados pela Whirlpool e pela participação e avaliação nos treinamentos da “Universidade Encantamento”, da “Escola Administrativa” e “Escola Técnica” por suas equipes (incluindo o titular/gestor) que podem ser acumulados e trocados por prêmios do catálogo online, acessível através do Site. </p>

                <p>EXEMPLO DE CÁLCULO MASTER:</p>
                <p>
                <table>
                    <tr>
                        <th class="criterios">CRITÉRIOS</th>
                        <th class="regras">REGRAS</th>
                        <th class="meta">METAS</th>
                        <th class="pontos">PONTOS</th>
                    </tr>
                    <tr>
                        <td><span>TLS</span></td>
                        <td>Média de tempo de atendimento das OS's (ordens de serviço)</td>
                        <td><span>
                        NO: 4,5<br />
                        CO: 4,5<br />
                        NE: 4,4<br />
                        SE: 3,8<br />
                        S: 3,5
                            </span></td>
                        <td><span>400</span></td>
                    </tr>
                    <tr>
                        <td><span>FCC (First Call Completed)</span></td>
                        <td>Avaliação do consumidor fornecida após o atendimento técnico via Whatsapp - percentual de respostas “1” - quanto maior, melhor. (métrica global)</td>
                        <td><span>70%</span></td>
                        <td><span>600</span></td>
                    </tr>
                    <tr>
                        <td><span>5 Stars Exp Ops</span></td>
                        <td>Avaliação do consumidor fornecida após o atendimento via Whatsapp - escala de 1 a 5 - quanto maior, melhor.</td>
                        <td><span>4,6</span></td>
                        <td><span>500</span></td>
                    </tr>
                    <!-- <tr>
                        <td><span>Crescimento Volume FG (%)</span></td>
                        <td>Medida do crescimento percentual de volume de Ordem de Serviço(OS) fora de garantia(FG). Cálculo realizado através do volume de OS FG do mês atual dividido pela média de volume total de OS FG dos 2 meses anteriores.</td>
                        <td><span>
                        Q1 - 6%<br />
                        Q2 - 7%<br />
                        Q3 - 12%<br />
                        Q4 - 12%
                        </span></td>
                        <td><span>300</span></td>
                    </tr> -->
                    <tr>
                        <td><span>Engajamento em Treinamento</span></td>
                        <td>Percentual de realização com aprovação dos treinamentos disponibilizados pela Whirlpool para equipe da Autorizada.</td>
                        <td><span>95%</span></td>
                        <td><span>300</span></td>
                    </tr>
                </table>
                </p>

                <p>EXEMPLO DE CÁLCULO ESTRELA:</p>
                <p>
                <table>
                    <tr>
                        <th class="criterios">CRITÉRIOS</th>
                        <th class="regras">REGRAS</th>
                        <th class="meta">METAS</th>
                        <th class="pontos">PONTOS</th>
                    </tr>
                    <tr>
                        <td><span>TAE</span></td>
                        <td>Média de tempo de atendimento das OS's (ordens de serviço)</td>
                        <td><span>9</span></td>
                        <td><span>500</span></td>
                    </tr>
                    <tr>
                        <td><span>FCC (First Call Completed)</span></td>
                        <td>Avaliação do consumidor fornecida após o atendimento técnico via Whatsapp - percentual de respostas “1” - quanto maior, melhor. (métrica global)</td>
                        <td><span>70%</span></td>
                        <td><span>400</span></td>
                    </tr>
                    <tr>
                        <td><span>5 Stars Exp Ops</span></td>
                        <td>Avaliação do consumidor fornecida após o atendimento via Whatsapp - escala de 1 a 5 - quanto maior, melhor.</td>
                        <td><span>4,6</span></td>
                        <td><span>300</span></td>
                    </tr>
                    <tr>
                        <td><span>Crescimento Volume FG (%)</span></td>
                        <td>Medida do crescimento percentual de volume de Ordem de Serviço(OS) fora de garantia(FG). Cálculo realizado através do volume de OS FG do mês atual dividido pela média de volume total de OS FG dos 2 meses anteriores.</td>
                        <td><span>
                        Q1 - 6%<br />
                        Q2 - 7%<br />
                        Q3 - 12%<br />
                        Q4 - 12%
                        </span></td>
                        <td><span>200</span></td>
                    </tr>
                    <tr>
                        <td><span>Engajamento em Treinamento</span></td>
                        <td>Percentual de realização com aprovação dos treinamentos disponibilizados pela Whirlpool para equipe da Autorizada.</td>
                        <td><span>95%</span></td>
                        <td><span>200</span></td>
                    </tr>

                </table>
                </p>

                <p>4.7.1 As Assistências Técnicas Autorizadas deverão ter no mínimo 10 (dez) pesquisas de satisfação respondidas no mês para ser elegível a pontuação dos indicadores “FCC (First Call Completed)” e “5 Estrelas”.</p>
                <p>4.7.2 As Assistências Técnicas Autorizadas receberão pontos de acordo com as metas atingidas mensalmente, conforme descrito acima.</p>
                <p>4.7.3 As Assistências Técnicas Autorizadas serão classificadas mensalmente de acordo com o <i>ranking</i> que considerará a somatória total dos pontos atingidos nos critérios mencionados.</p>
                <p>4.7.3.1 Caso haja empate, a regra de desempate no ranking seguirá a seguinte ordem: maior pontuação; maior performance em FCC Ops; menor TLS/TAE; maior performance 5 Stars Exp Ops; maior aumento no Crescimento Volume FG (%), maior pontuação em treinamentos e, por fim, maior tempo de cadastro no Programa.</p>
                <p>4.8 As Assistências Técnicas Autorizadas poderão incluir ou inativar participantes em qualquer período do mês no programa, mas as alterações realizadas após o dia 15 de cada mês, seguirão a regra estabelecida no item 2.5. É de responsabilidade da assistências técnicas a atualização do status dos colaboradores cadastrados, sendo possível realizar o desbloqueio, afastamento e/ou inativação do usuário. Após 60 dias corridos sem acesso a plataforma(login), o usuário será automaticamente bloqueado, até a solicitação da ativação através da Central de Atendimento do Participante (item 2.2.1).</p>
                <p>4.9 É de inteira responsabilidade da Assistência Técnica Autorizada manter a lista de cadastrados atualizada e os dados de seus funcionários corretamente preenchidos.
                </p>
                <p>4.10 As Assistências Técnicas Autorizadas receberão a pontuação por treinamento somente quando houver o atingimento de 95% de participação mensal (com aprovação: mínimo 90% de acertos) dos funcionários cadastrados no Programa Tec Club+.</p>
            </div>
        </div>
        <div style="page-break-before:always; page-break-after:always">
            <div>
            <p><b>5. PREMIAÇÃO NO PORTAL DE PRÊMIOS </b></p>
                <p>5.1 Os pontos acumulados pelos Participantes neste Programa poderão ser trocados por produtos do catálogo disponibilizado no site de prêmios (“Prêmio”). As condições para a troca dos pontos por Prêmios serão aquelas estabelecidas pelo site de prêmios. </p>

                <p>5.2 No momento em que for solicitado o resgate, será verificado se a quantidade de pontos válidos é suficiente para a troca desejada. Em caso negativo, o Participante será informado da insuficiência de saldo para resgate, uma vez que não é possível utilizar, cumulativamente, pontos e recursos financeiros próprios para o resgate de Prêmios. </p>
                <p>5.3 As pontuações referentes à apuração estarão disponíveis para o Participante até o 10º (décimo) dia útil do mês subsequente, salvo períodos de feriados e datas de eventos com a Rede Autorizada. Os saldos serão atualizados até 24 horas após o crédito.</p>
                <p>5.4 No momento em que for solicitado o resgate, no caso de indisponibilidade do Prêmio, o Participante poderá optar por outro modelo ou tipo.</p>
                <p>5.5 O resgate dos pontos poderá ser parcial ou aguardar o acúmulo de pontos até as datas estipuladas neste Programa.</p>
                <p>5.6 O resgate dos prêmios da Assistência Técnica Autorizada, serão realizados pelo Titular Legal.</p>
                <p>5.7 Em caso de instabilidade/manutenção da plataforma de prêmio, o período de validade da pontuação será ajustado conforme a duração da instabilidade/manutenção.</p>

                <p><b>6. DA VALIDADE DOS PONTOS E DA ENTREGA DOS PRÊMIOS</b></p>
                <p>6.1 Observados e cumpridos todas as disposições deste instrumento, incluindo, mas não se limitando à Cláusula 2ª, salvo disposição contrária, o Participante terá até 12 (doze) meses após a data de crédito da pontuação para resgatar os pontos acumulados, realizando a troca por Prêmios na Plataforma de Prêmios.</p>
                <p>6.1.1. Após o término do prazo acima mencionado, os pontos não estarão mais disponíveis para resgate do Participante, expirando-se automaticamente.</p>
                <p>6.2. Os Prêmios resgatados serão entregues pela empresa responsável no endereço cadastrado pelo Participante no Site, de acordo com o prazo de entrega de cada parceiro, considerando que o Prêmio esteja disponível em estoque.</p>
                <p>6.3. A partir do resgate, o Participante contemplado deverá seguir as condições e regras gerais da Política de troca da empresa responsável pelo site de prêmios, ficando a Whirlpool totalmente isenta de qualquer responsabilidade pelo envio e disponibilidade do prêmio resgatado.</p>
                <p>6.3.1.  Fica determinado que eventuais despesas com frete para entrega dos Prêmios resgatados serão suportadas pelo Participante.</p>
                <p>6.4. Toda pontuação e os prêmios concedidos são pessoais e intransferíveis.</p>

            </div>
        </div>
        <div style="page-break-before:always; page-break-after:always">
            <div>
                <p>6.5.  Não será permitido ao Participante, em hipótese alguma, trocar sua pontuação ou Prêmios resgatados por qualquer outro bem, produto ou serviço, nem mesmo por dinheiro.</p>
                <p>6.6 O prazo para contestação de qualquer pontuação estipulado nestes Termos é de 7 dias corridos a partir da data do seu crédito ou da data em que tal crédito deveria ter sido computado, não podendo o Participante reclamar sobre a pontuação após este prazo.</p>
                <p>6.7 Serão automaticamente excluídos os pontos no Programa, sem necessidade de prévia comunicação, do Participante que praticar qualquer tipo de ato considerado fraudulento, ilegal, ilícito, ou que atente contra os objetivos deste Programa e/ou estes termos e condições, assim como suspenso o período de resgate citado no item 2.4.1 desde regulamento.</p>
                
                <p><b>7. CAMPANHAS ADICIONAIS</b></p>
                <p>7.1.  Campanhas adicionais são aquelas lançadas pela Whirlpool sazonalmente no Site.</p>
                <p>7.2.  Para participação de campanhas adicionais, as pessoas elegíveis para participar da correspondente campanha adicional deverão acessar o Site e aceitar a respectiva Campanha.</p>
                <p>7.3.  Para apuração de campanhas adicionais, serão considerados válidos apenas os resultados dos Participantes que confirmaram a sua participação no Site.</p>

                <p><b>8. INFORMAÇÕES SOBRE A PROPRIEDADE INTELECTUAL E DIREITOS AUTORAIS</b></p>
                <p>8.1 Ao aceitar este Regulamento, o Participante reconhece e concorda que todo o conteúdo apresentado no Site está protegido por direito autoral e direito de propriedade intelectual. O Participante só pode usar o conteúdo conforme expressamente neste regulamento, ou seja, para o seu uso pessoal sem que isso signifique qualquer tipo de licença. O Participante não pode copiar, reproduzir, modificar, re-publicar, carregar, publicar, transmitir ou distribuir qualquer documento ou informação do Site de qualquer forma ou por outro meio. O uso não autorizado dos materiais que aparecem no Site pode violar direitos autorais e direito de propriedade intelectual, podendo resultar em penalidades criminais ou civis.</p>

                <p><b>9. ACEITAÇÃO TOTAL DOS TERMOS</b></p>
                <p>9.1. O Participante manifesta expressamente ter a capacidade legal para usar o Site. Assim, manifesta ter fornecido informação real, veraz e fidedigna. Portanto, de forma expressa e inequívoca declara ter lido e que aceita todas as disposições contidas nestes Termos e Condições.</p>
            </div>
        </div>
        <div style="page-break-before:always; page-break-after:always">
            <div>
                <p><b>10. CONSIDERAÇÕES GERAIS</b></p>
                <p>10.1. Ao participar deste Programa e aceitar os termos e condições deste Regulamento, os Participantes autorizam o uso de sua imagem e voz, bem como os direitos de expor, publicar, reproduzir, armazenar e/ou de qualquer outra forma delas se utilizarem, o que os Participantes fazem de modo expresso e em caráter irrevogável e irretratável, desde já e de pleno direito, em caráter gratuito e sem qualquer remuneração, ônus ou encargo, podendo referidos direitos serem exercidos por meio de cartazes, filmes e/ou spots, jingles e/ou vinhetas, bem como em qualquer tipo de mídia e/ou peças promocionais, inclusive em televisão, rádio, jornal, cartazes, faixas, outdoors, mala-direta e na Internet, para a ampla divulgação deste Regulamento e/ou de seu desenvolvimento posterior, com exclusividade e sem que tal autorização signifique, implique ou resulte em qualquer obrigação de divulgação nem de pagamento, concordando ainda, inclusive, em assinar eventuais recibos e instrumentos neste sentido e para tal efeito, sempre que solicitado pela Realizadora.</p>
                <p>10.2. Aceitando participar do Programa e estes Termos e Condições, o Participante entende que não estabelece nenhum vínculo de natureza empregatícia, subordinação ou de responsabilidade entre a Whirlpool e o Participante.</p>
                <p>10.3. O Programa, por exclusivo critério da Whirlpool e a qualquer tempo, poderá ser interrompido, suspenso ou encerrado, bem como pode ter os seus termos e condições modificados a qualquer tempo. Nesses casos, todas as informações pertinentes serão divulgadas através de e-mail ou outro meio de comunicação escolhido pela Whirlpool. No caso de encerramento do Programa, os Participantes farão jus ao prazo indicado no item 6.1 para o resgate dos pontos até então obtidos.</p>
                <p>10.4. A participação no Programa será voluntária e facultativa, não havendo qualquer sanção àqueles que optarem por não participar.</p>
                <p>10.5. Para obter esclarecimentos sobre este Programa, os interessados poderão fazer contato com a Whirlpool através do telefone <b>0800 236 0011</b>, “Fale Conosco” no Site ou por meio da Central de Atendimento ao Participante (suporte@programatecclub.com.br).</p>
                <p>10.6. As dúvidas não esclarecidas neste instrumento serão dirimidas pela Whirlpool, de forma soberana e irrecorrível.</p>
                <p>10.7. O Participante se obriga a manter atualizados seus dados cadastrais junto ao Site. Desse modo, comunicações enviadas ao Participante serão tidas como devidamente efetivadas se dirigidas a um dos meios de contato presentes no cadastro.</p>
                <p>10.8. Tendo em vista as características inerentes ao ambiente da Internet, a Whirlpool não garante o pleno funcionamento e acesso ao Site de maneira ininterrupta. Ademais, a Whirlpool não garante que o Site esteja livre de invasões, interrupções ou suspensões. O Participante reconhece e concorda que a Whirlpool não poderá ser responsabilizada por tais fatos e/ou atos em decorrência de hipótese de força maior ou caso fortuito.</p>
            </div>
        </div>
        <div style="page-break-before:always; page-break-after:always">
            <div>
                <p>10.8.1. Em razão do exposto no item anterior, na hipótese de ocorrência de invasões, interrupções ou suspensões de qualquer gênero, durante o período de vigência do Programa, a Whirlpool não ficará obrigada a prorrogar o prazo do mesmo, salvo decisão em contrário a exclusivo critério da Whirlpool.</p>
                <p>10.8.2. O Participante reconhece que dada as características do Programa no ambiente da Internet, terceiros de má-fé poderão remotamente tentar utilizar-se de subterfúgios tecnológicos a fim de terem acesso a dados pessoais do Participante ou a fraudar o Programa. Diante disso, o Participante também reconhece que a Whirlpool não poderá ser responsabilizada pelos atos praticados por terceiros.</p>
                <p>10.8.3. A Whirlpool se reserva o direito de efetuar toda e qualquer ação corretiva que se aplique às ações de terceiros que venham a interferir no bom funcionamento do Programa, como também se reserva o direito de efetuar análises periódicas, baseadas nos registros dos Participantes inscritos, hipótese em que, se constatadas inconsistências, fica-lhe facultado o direito de efetuar automático bloqueio da respectiva conta.</p>
                <p>10.9. A participação nesta Campanha implica na aceitação total de todos os itens deste Regulamento, não sendo dado a qualquer dos Participantes o direito de alterá-lo. </p>
                <p>10.9.1. O Regulamento poderá ser alterado pela Whirlpool tantas vezes quanto necessário, a seu exclusivo critério, com posterior divulgação.</p>
                <p>10.9.2. A participação não implica em qualquer exclusividade de atendimento para a Rede multimarcas (“ESTRELA”), sendo os Participantes livres para realizar atendimento quaisquer produtos de outras empresas.</p>
                <p>10.10. A participação na Campanha é voluntária e gratuita, não estando sujeita a qualquer espécie de cobrança ou à necessidade de desembolso por parte dos Participantes.</p>
                <p>10.12. Em caso de fraude comprovada ou descumprimento deste Regulamento, o Participante será automaticamente excluído da Campanha.</p>
                <p>10.12. <b>Lei aplicável e foro</b>. Esses termos e condições são regidos, interpretados e executados de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente o Foro da Comarca de São Paulo (SP), para dirimir qualquer dúvida decorrente deste instrumento. O Participante consente, expressamente, com a competência desse juízo, e renuncia, neste ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha a ser.</p>
                <p><b>Versão:  1 de Agosto de 2024.</b></p>
          
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
export default {
  name: "Regulamento",

  components: {
    PerfectScrollbar
  }
}
</script>

<style lang="scss" scoped>
.ps {
  height: 412px;
  @media(max-width: 768px) {
    height: 302px;
  }
}
.modalPolitica {
  // width: 100vw;
  // height: 100vh;
  // position: fixed;
  // z-index: 2;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // background-color: #444444b0;


  @media (max-width: 768px) {
    padding: 0 13px;
    margin-bottom: 20px;
  }

  &__container {
    // width: 762px;
    background: $branco;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    width: 733px;
    @media (max-width: 768px) {
      width: 100%;
    }

    h1 {
      font-weight: bold;
      font-size: 25px;
      text-align: center;
      color: $grafite;
      margin: 30px 0;

      @media (max-width: 768px) {
        width: 100%;
        text-align: center;
        font-size: 24px;
        margin-bottom: 23px;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 32px;
      color: $grafite;
      margin-bottom: 30px;
      // padding: 0 45px;
      font-weight: bold;
      width: 100%;

      @media (max-width: 768px) {
        padding: 0 24px;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
      }
    }
  }

  &__politica {
    // padding: 0 45px;
    padding: 0 25px;

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $grafite;
      margin-bottom: 30px;
    }

    span {
      font-weight: bold;
    }

    table {
      margin-bottom: 30px;
      width: 100%;
      border: 1px solid $grafite;
      color: $grafite;

      th,
      td {
        padding: 20px;
        border: 1px solid $grafite;
        text-align: center;
        vertical-align: middle;
        @media (max-width: 768px) {
          padding: 5px;
        }
      }
    }

    .azul {
      color: #0070c0;
      text-decoration: underline;
      font-weight: normal;
    }
  }
}
</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>