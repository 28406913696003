<template>
  <div class="carrouselPrograma">
    <vueper-slides>
      <vueper-slide v-for="item in CarrouselPrograma" :key="item.id">
        <template v-slot:content>
          <div class="carrouselPrograma__itens">
            <img class="mobile" :src="item.imgMobile" />
            <img class="desktop" :src="item.imgDesktop" />
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import CarrouselPrograma from "@/assets/json/CarrouselPrograma.json";
export default {
  name: "CarrouselPrograma",

  components: {
    VueperSlides,
    VueperSlide,
  },

  data() {
    return {
      CarrouselPrograma: CarrouselPrograma,
    };
  },
};
</script>

<style lang="scss" scoped>
.carrouselPrograma {
  margin-bottom: 20px;
  &__itens {
    .mobile {
      display: block;
      width: 302px !important;
      height: 302px !important;
      @media (min-width: 768px) {
        display: none;
      }
    }

    .desktop {
      display: none;
      @media (min-width: 768px) {
        display: block;
        width: 733px;
        height: 412px;
      }
    }
  }
}
</style>