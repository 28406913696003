<template>
  <header>
    <div :class="headerGrande ? 'header__mobile bg-laranja-home' : 'header__mobile bg-laranja'">
      <LogoBranca />
      <img @click="abrirMenu" class="header__container__menuMobile" src="~@/assets/images/tema/menu-mobile.png"
        alt="Menu hambúrguer" />
    </div>
    <div class="header bg-laranja" :class="aplicaEstilos()">
      <div :class="{ headerGrande: headerGrande }">
        <div class="header__container">
          <div class="logoHeader" @click="irParaHome">
            <LogoBranca />
          </div>
          <img @click="abrirMenu" class="header__container__menuMobile" src="~@/assets/images/tema/menu-mobile.png"
            alt="Menu hambúrguer" />
          <div class="nav">
            <nav>
              <ul>
                <router-link to="/">
                  <li>Home</li>
                </router-link>
                <router-link to="/sobre-o-programa" v-if="autorizaTelas && this.boxSelectAutorizada.autorizadaId != '207'">
                  <li>O Programa</li>
                </router-link>
                <router-link to="/campanhas">
                  <li>Campanhas</li>
                </router-link>
                <router-link to="/performance" v-if="autorizaTelas && this.boxSelectAutorizada.autorizadaId != '207'">
                  <li>Performance</li>
                </router-link>
                <li class="catalogo" @click="catalogoPremios" v-if="cargoUsuario != 9 && cargoUsuario != 10">
                  Catálogo de prêmios
                </li>
                <router-link to="/faq" v-if="autorizaTelas">
                  <li>FAQ</li>
                </router-link>
              </ul>
            </nav>
            <div class="divisor"></div>
            <div class="perfil-relative">
              <div class="perfil">
                <router-link to="/meu-perfil">
                  <img v-if="fotoUsuario" :src="fotoUsuario" alt="Foto perfil" />
                  <div class="semFoto" v-else>
                    <p>{{ iniciaisNome }}</p>
                  </div>
                </router-link>
                <div class="sair">
                  <a @click="sair">Sair</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="select-autorizada desktop" v-if="cargoUsuario == 10 && exibeAutorizadas">
          <div class="conteudo">
            <p class="texto">Selecione a assistência:</p>
            <Multiselect v-model="boxSelectAutorizada" label="autorizadaNomeFantasia" track-by="autorizadaId"
              :options="autorizadasUsuario" :multiple="false" placeholder=" " select-label=" " deselect-label=" "
              selected-label=" " :searchable="true" :allowEmpty="false">
              <span slot="noResult">Não encontrado</span>
            </Multiselect>
          </div>
        </div>
      </div>
    </div>

    <div class="header__menuInativo" :class="{ header__menuAtivo: menuAtivo }">
      <div class="header__logo">
        <LogoBranca />
        <img @click="fecharMenu" class="header__logo__fecharMenu" src="~@/assets/images/tema/fechar-meunu.png"
          alt="Menu hambúrguer" />
      </div>
      <div class="select-autorizada" v-if="cargoUsuario == 10 && exibeAutorizadas">
        <div class="conteudo">
          <p class="texto">Selecione a assistência:</p>
          <Multiselect v-model="boxSelectAutorizada" label="autorizadaNomeFantasia" track-by="autorizadaId"
            :options="autorizadasUsuario" :multiple="false" placeholder=" " select-label=" " deselect-label=" "
            selected-label=" " :searchable="true" :allowEmpty="false">
            <span slot="noResult">Não encontrado</span>
          </Multiselect>
        </div>
      </div>


      <nav class="header__meuItem">
        <div class="divisor-select"></div>
        <ul>
          <router-link to="/">
            <li>Home</li>
          </router-link>
          <router-link to="/sobre-o-programa" v-if="autorizaTelas && this.boxSelectAutorizada.autorizadaId != '207'">
            <li>O Programa</li>
          </router-link>
          <router-link to="/campanhas">
            <li>Campanhas</li>
          </router-link>
          <router-link to="/performance" v-if="autorizaTelas && this.boxSelectAutorizada.autorizadaId != '207'">
            <li>Performance</li>
          </router-link>
          <li class="catalogo" @click="catalogoPremios" v-if="cargoUsuario != 9 && cargoUsuario != 10">
            Catálogo de prêmios
          </li>
          <router-link to="/faq" v-if="autorizaTelas">
            <li>FAQ</li>
          </router-link>
          <router-link to="/meu-perfil">
            <li>Meu Perfil</li>
          </router-link>
        </ul>
        <div class="divisor-select"></div>
      </nav>

      <div @click="sair" class="header__meuItem__sair">
        <img src="~@/assets/images/tema/menu-sair.png" alt="Sair" />
        <p>Sair</p>
      </div>
    </div>

    <div :class="headerGrande ? 'espacador-topo-home' : 'espacador-topo'" v-if="exibeAutorizadas"></div>
  </header>
</template>

<script>
import LogoBranca from "@/components/logos/LogoBranca.vue";
import { irParaCatalogo } from "@/api/catalogo";
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "Header",

  components: {
    LogoBranca,
    Multiselect
  },

  props: {
    headerGrande: Boolean,
    exibeAutorizadas: Boolean,
    pagHome: Boolean
  },

  data() {
    return {
      menuAtivo: false,
      menuDesktop: false,
      boxSelectAutorizada: [],
    };
  },

  computed: {
    ...mapState({
      autorizadasUsuario: (state) => state.usuario.autorizada,
      autorizadaAtiva: (state) => state.usuario.autorizadaAtiva,
      autorizadaSelect: (state) => state.usuario.autorizadaSelect,
      autorizaTelas: (state) => state.usuario.autorizaTelas,
      iniciaisNome: (state) => state.usuario.iniciaisNome,
      cargoUsuario: (state) => state.usuario.cargoUsuario,
      fotoUsuario: (state) => state.usuario.fotoUsuario,
    }),
  },

  watch: {
    boxSelectAutorizada() {
      //define autorizada ativa
      this.selecionaAutorizada(this.boxSelectAutorizada.autorizadaId);

      //define a autorizada no select
      this.defineSelectAutorizada(this.boxSelectAutorizada);

      //fecha menu mobile
      this.fecharMenu();
    },
    autorizadaAtiva() {
      this.boxSelectAutorizada = this.autorizadaSelect;
    }
  },

  methods: {
    ...mapActions({
      limparStates: "usuario/limparStates",
      defineFotoUsuario: "usuario/defineFotoUsuario",
      selecionaAutorizada: "usuario/selecionaAutorizada",
      defineSelectAutorizada: "usuario/defineSelectAutorizada",
    }),
    aplicaEstilos() {
      let estilo = '';
      if (this.cargoUsuario == 10 && this.exibeAutorizadas) estilo = 'pag-home';
      if (this.pagHome) estilo = estilo + ' z-index-home';
      return estilo
    },
    catalogoPremios() {
      if (localStorage.getItem("espelho")) {
        return;
      }

      irParaCatalogo().then((resp) => {
        if (resp.data.url == null) {
          this.$store.commit("interacoes/SET_MODAL_CADASTRO_COMPLETO", {
            ativado: true,
            mensagem: "Modal",
            tipo: "",
          });
        } else {
          window.open(resp.data.url, "_blank");
        }
      });
    },
    buscarFotoUsuario() {
      if (this.fotoUsuario != null) return;
      if (
        localStorage.getItem("fotoUsuario") &&
        localStorage.getItem("fotoUsuario") !== "null"
      ) {
        this.defineFotoUsuario(localStorage.getItem("fotoUsuario"));
      } else {
        localStorage.removeItem("fotoUsuario");
        this.foto = false;
      }
    },
    abrirMenu() {
      this.menuAtivo = true;
    },

    fecharMenu() {
      this.menuAtivo = false;
    },

    abrirMenuDesktop() {
      this.menuDesktop = !this.menuDesktop;
    },

    sair() {
      localStorage.removeItem("fotoUsuario");
      localStorage.removeItem("idUser");
      localStorage.removeItem("espelho");
      localStorage.removeItem("totalPontos");
      this.limparStates();
      this.$router.push("/login");
    },
    irParaHome() {
      this.$router.push("/");
    }
  },

  mounted() {
    this.buscarFotoUsuario();
    this.boxSelectAutorizada = this.autorizadaSelect;
  },
};
</script>

<style lang="scss">
.conteudo {
  .multiselect {
    min-height: 0px;
    height: 30px;

    @media (max-width: 768px) {
      top: 9px;
      width: 320px;
    }
  }

  .multiselect__tags {
    border: 1px solid white;
    background: none !important;
    border-radius: 16px;
    width: 400px;
    height: 25px !important;
    padding: 9px 0 0 5px !important;
    border-radius: 10px;

    @media (max-width: 768px) {
      width: 320px;
    }
  }

  .multiselect__single {
    background: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 340px;
    overflow: hidden;
    color: $branco;
    font-size: 14px;

    @media (max-width: 768px) {
      width: 250px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      height: 20px;
    }
  }

  .multiselect__input {
    background-color: transparent;
  }

  .multiselect__content-wrapper {
    position: absolute;
    padding: 0;
  }

  .multiselect__select:before {
    height: 30px;
    border-color: #FFF transparent transparent;
    z-index: 99;
  }

  .multiselect__select {
    height: 25px !important;
    top: 6px;
  }

  .multiselect__placeholder {
    color: $branco;
    margin-left: 10px;
    font-size: 13px;
    margin-bottom: 0px;
    padding-top: 3px;
  }

  .multiselect__single {
    background: none;
  }
}
</style>

<style lang="scss" scoped>
.z-index-home {
  @media (max-width: 768px) {
    z-index: 0 !important;
  }
}

.espacador-topo {
  width: 100%;
  margin-bottom: 130px;

  @media(max-width: 768px) {
    margin-bottom: 130px;
  }
}

.espacador-topo-home {
  width: 100%;
  margin-bottom: 150px;

  @media(max-width: 768px) {
    margin-top: 190px;
  }
}

.bg-laranja {
  background: $bg-laranja;
  width: 100%;
  position: relative;
  z-index: 1;

  &.pag-home {
    height: 130px;

    @media(max-width: 768px) {
      height: auto;
      z-index: 5;
    }
  }
}

.divisor-select {
  max-width: 320px;
  border-bottom: 2px solid rgba(255, 255, 255, .5);
  margin-top: 23px;
  width: 100%;
}

.select-autorizada {
  height: 50px;
  border-top: 1px solid #fff;

  @media (max-width: 768px) {
    border-top: none;
  }

  .conteudo {
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-auto-flow: column;
    justify-content: right;
    align-items: center;
    height: 40px;
    margin: 5px auto;
    color: $branco;
    font-size: 14px;
    position: relative;

    @media (max-width: 768px) {
      display: block;
      margin-left: 35px;
      font-size: 16px;
    }

    .texto {
      margin-right: 10px;
      padding-top: 10px;
    }
  }
}

.catalogo {
  color: $branco;
  cursor: pointer;
}

.logoHeader {
  cursor: pointer;
}

.off-campanha {
  position: relative;

  &:hover {
    .breve {
      display: block;
    }
  }

  .breve {
    display: none;
    background: $branco;
    padding: 5px;
    font-size: 10px;
    border-radius: 5px;
    color: $grafite;
    position: absolute;
    left: 20px;
    bottom: 20px;

    @media (max-width: 768px) {
      display: block;
      left: 140px;
      bottom: -23px;
      white-space: nowrap;
    }
  }
}

.headerGrande {
  height: 190px !important;
  position: relative;
  z-index: -1;

  @media (min-width: 768px) {
    height: 70px !important;
  }
}

.bg-laranja-home {
  background: linear-gradient(#f7941d 0%, #e64e20 280%);
}

.header {
  width: 100%;
  position: fixed;
  z-index: 3;
  top: 0;

  @media(max-width: 768px) {
    z-index: 1;
  }

  &__mobile {
    position: fixed;
    z-index: 5;
    top: 0;
    height: 70px;
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    @media (min-width: 990px) {
      display: none;
    }
  }

  &__container {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 17px 26px 7px 24px;

    @media (min-width: 768px) {
      max-width: 1240px;
      margin: 0 auto;
      align-items: center;
      height: 100%;

      // padding-top: 20px;
      // padding-right: 26px;
    }

    &__menuMobile {
      width: 50px;
      height: 50px;
      cursor: pointer;

      @media (min-width: 768px) {
        display: none;
      }
    }

    .nav {
      display: none;

      @media (min-width: 768px) {
        display: flex;
        align-items: center;

        ul {
          display: flex;
          align-items: center;
        }

        a {
          text-decoration: none;
          color: $branco;
          font-size: 16px;
        }

        li {
          margin-right: 15px;
        }
      }

      .perfil {
        position: relative;
        display: flex;
        align-items: center;

        .sair {
          cursor: pointer;
          margin-left: 15px;

          a {
            font-size: 14px;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .semFoto {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 43px;
          height: 43px;
          background: $branco;
          position: static;
          border-radius: 50%;

          p {
            color: orangered;
            font-size: 18px;
          }
        }

        img {
          width: 43px;
          height: 43px;
          border-radius: 50%;
        }

        .meuPerfil.perfil-icone {
          background: transparent;

          &::after {
            //  transform: rotate(-135deg);
            background: $branco;
            width: 1px;
            transform: rotate(-40deg);
            border: 1px solid $branco;
          }

          &::before {
            // transform: rotate(135deg);
            transform: rotate(40deg);
            border: 1px solid $branco;
          }
        }

        .meuPerfil-inativo {
          display: none;
        }

        .meuPerfil-inativo.meuPerfil {
          display: block;
          background: #1f1e1e;
          position: absolute;
          left: 47px;
          top: 40px;
          border-radius: 5px;
          width: 80px;
          padding: 10px 5px;
          z-index: 2;
          color: $branco;

          .item {
            &:not(:last-child) {
              margin-bottom: 10px;
            }

            p {
              font-size: 12px;
              cursor: pointer;
            }
          }

          &::after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            background: #1f1e1e;
            top: -5px;
            left: 10px;
            transform: rotate(-135deg);
            transition: all 0.3s;
          }
        }

        .perfil-icone {
          position: absolute;
          top: 15px;
          left: 55px;
          width: 20px;
          height: 20px;
          cursor: pointer;

          &::after {
            position: absolute;
            content: "";
            height: 15px;
            border: 1px solid $branco;
            top: 0;
            left: 10px;
            transform: rotate(-135deg);
            transition: all 0.3s;
          }

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            height: 15px;
            border: 1px solid $branco;
            transform: rotate(135deg);
            transition: all 0.3s;
          }
        }
      }
    }
  }

  &__menuInativo {
    width: 100%;
    height: 808px;
    background: $bg-laranja;
    z-index: 2;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    transition: all 0.3s;
  }

  &__menuAtivo {
    transform: translateY(0%);
    position: fixed;
    z-index: 5;
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 37px 0 24px;

    &__fecharMenu {
      width: 24px;
      height: 24px;
    }
  }

  &__meuItem {
    padding: 24px 0 0 34px;
    z-index: 10;

    ul {
      a {
        text-decoration: none;
      }

      li {
        margin: 30px 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: $branco;
      }
    }

    &__sair {
      margin-left: 34px;
      margin-top: 30px;
      display: flex;
      align-items: center;

      img {
        width: 37px;
        height: 37px;
        margin-right: 13px;
      }

      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: $branco;
      }
    }
  }
}
</style>
