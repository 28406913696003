<template>
  <div class="filtrarBusca">
    <p v-if="mostrarTitulo" class="titulo-mobile">Filtrar busca</p>
    <div class="separador">
      <div class="filtrarBusca__filtro" id="multiselect">
        <p v-if="mostrarTitulo" class="titulo-desktop">Filtrar busca</p>
        <div class="filtrarBusca__filtro__tipo" id="origem-kpi">
          <Multiselect
            v-model="filtro.origemkpiId"
            label="nome"
            track-by="id"
            :options="kpis"
            :multiple="false"
            :options-limit="200"
            placeholder="Selecione o KPI"
            select-label=" "
            deselect-label=" "
            selected-label=" "
            :searchable="false"
          />
        </div>
        <div class="filtrarBusca__filtro__container">
          <div class="filtrarBusca__filtro__mes">
            <Multiselect
              v-model="filtro.mes"
              label="mes"
              track-by="id"
              :options="mesesFiltro"
              :multiple="false"
              :options-limit="200"
              placeholder=" "
              select-label=" "
              deselect-label=" "
              selected-label=" "
              :searchable="false"
            >
              <i slot="noOptions">Sem resultado</i>
            </Multiselect>
          </div>
          <div class="filtrarBusca__filtro__ano">
            <InputAno
              @mesesLista="mesesFiltro = $event"
              @mesAtual="filtro.mes = $event"
              @anoEscolhido="filtro.ano = $event"
            />
            <!-- <Multiselect v-model="filtro.ano" label="ano" track-by="id" :options="ano" :multiple="false"
							:options-limit="200" placeholder=" " select-label=" " deselect-label=" " selected-label=" "
							:searchable="false" :allow-empty="false" @input="obterMesesDownload()"/> -->
          </div>
        </div>
      </div>
      <div class="button">
        <button @click="graficoFiltrado">BUSCAR</button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import InputAno from "@/components/formulario/InputAno.vue";
import {
  obterKpisPorAutorizada,
  obterComboAtualizacaoPerformance,
} from "@/api/performance";
//import { obterMesesComPerformance } from "@/api/performance";
import { mapState, mapActions } from "vuex";

export default {
  name: "FiltrarDesempenho",

  props: {
    mostrarTitulo: {
      type: Boolean,
      default: true,
    },

    tipo: {
      type: Boolean,
      default: false,
    },

    filtrarGrafico: Function,

    tipoExtrato: Boolean,
  },

  components: {
    Multiselect,
    InputAno,
  },

  computed: {
    ...mapState({
      autorizadaAtiva: (state) => state.usuario.autorizadaAtiva,
      cargoId: (state) => state.usuario.cargoUsuario,
    }),
  },

  data() {
    return {
      mes: [
        { id: 1, mes: "Janeiro" },
        { id: 2, mes: "Fevereiro" },
        { id: 3, mes: "Março" },
        { id: 4, mes: "Abril" },
        { id: 5, mes: "Maio" },
        { id: 6, mes: "Junho" },
        { id: 7, mes: "Julho" },
        { id: 8, mes: "Agosto" },
        { id: 9, mes: "Setembro" },
        { id: 10, mes: "Outubro" },
        { id: 11, mes: "Novembro" },
        { id: 12, mes: "Dezembro" },
      ],
      // ano: [
      //   { id: 2021, ano: 2021 },
      //   { id: 2022, ano: 2022 },
      //   { id: 2023, ano: 2023 },
      //   { id: 2024, ano: 2024 },
      // ],
      mesesFiltro: [],
      filtro: {
        mes: null,
        ano: null,
        origemkpiId: {
          nome: "FCC",
          dataCadastro: "2021-09-27T15:20:00",
          ativo: true,
          id: 2,
        },
        autorizadaId: null,
      },

      kpis: [],
    };
  },

  mounted() {
    this.obterUltimaAtualizacao();
  },

  watch: {
    autorizadaAtiva() {
      //   this.obterMesesDownload();
      this.obterKpisAutorizada();
      this.graficoFiltrado();
    },
    "filtro.mes"() {
      this.defineMesAnoTreinamentos(this.filtro);
    },
    "filtro.ano"() {
      this.defineMesAnoTreinamentos(this.filtro);
    },
  },

  methods: {
    ...mapActions({
      defineMesAnoTreinamentos: "extrato/defineMesAnoTreinamentos",
    }),
    // obterMesesDownload() {
    //   obterMesesComPerformance(this.filtro.ano.id, this.autorizadaAtiva).then(
    //     (resp) => {
    //       let res = resp.data.sort();
    //       this.mesesFiltro = res.map((item) =>
    //         this.mes.find((nMes) => nMes.id == item)
    //       );
    //       this.mesesFiltro.sort(function (a, b) {
    //         if (a.id > b.id) {
    //           return 1;
    //         }
    //         if (a.id < b.id) {
    //           return -1;
    //         }
    //       });
    //       this.filtro.mes = this.mesesFiltro[this.mesesFiltro.length - 1];
    //     }
    //   );
    // },
    defineData() {
      let ano = new Date().getFullYear();
      this.filtro.ano = this.ano.find((element) => element.id == ano);
    },
    graficoFiltrado() {
      if (this.tipoExtrato) {
        const data = {
          mes: this.filtro.mes ? this.filtro.mes.id : 1,
          ano: this.filtro.ano ? this.filtro.ano.id : new Date().getFullYear(),
        };

        this.$store.commit("extrato/SET_FILTRO_EXTRATO", data);
        this.filtrarGrafico(data);
      } else {
        let autorizadaId = null;
        if (this.cargoId == 8 || this.cargoId == 9) {
          autorizadaId = parseInt(this.autorizadaAtiva);
        }
        const data = {
          mes: this.filtro.mes !== null ? this.filtro.mes.id : 0,
          ano: this.filtro.ano !== null ? this.filtro.ano.id : 0,
          origemkpiId: this.filtro.origemkpiId.id
            ? this.filtro.origemkpiId.id
            : 0,
          autorizadaId: autorizadaId,
        };
        this.filtrarGrafico(data);
      }
      this.obterKpisAutorizada();
      this.defineMesAnoTreinamentos(this.filtro);
    },
    obterUltimaAtualizacao() {
      obterComboAtualizacaoPerformance().then((resp) => {
        var data = resp.data.split("/");
        this.filtro.mes = this.mes.find((item) => item.id == data[0]);
        this.filtro.ano = this.ano.find((item) => item.id == data[1]);

        this.obterKpisAutorizada();
        this.graficoFiltrado();
        // this.obterMesesDownload();
      });
    },
    obterKpisAutorizada() {
      obterKpisPorAutorizada(
        this.autorizadaAtiva,
        this.filtro.mes.id,
        this.filtro.ano.id
      ).then((resp) => {
        this.kpis = resp.data;
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
#origem-kpi {
  .multiselect__single {
    @media (min-width: 768px) {
      width: 105% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.noOptions {
  width: 100px;
  height: 30px;
}
.filtrarBusca {
  margin: 0;

  .titulo-mobile {
    display: block;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .titulo-desktop {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  @media (min-width: 768px) {
    display: flex;

    .separador {
      display: flex;
      // margin-left: 25px;
    }
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: $grafite;
    margin-bottom: 16px;
    text-align: left;

    @media (min-width: 768px) {
      margin-bottom: 0px;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border: none;
      width: 302px;
      height: 50px;
      background: $bg-verde;
      border-radius: 5px;

      font-weight: 500;
      font-size: 14px;
      color: $branco;

      margin-top: 14px;
      margin-bottom: 30px;

      @media (min-width: 768px) {
        width: 150px;
        height: 50px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 15px;
      }
    }
  }

  &__filtro {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__tipo {
      width: 100%;
      max-width: 300px;
      margin-bottom: 5px;
    }

    @media (min-width: 768px) {
      width: 100%;
      flex-direction: row;

      p {
        width: 145px;
      }

      &__container {
        width: auto;
        display: flex;
      }

      &__tipo {
        width: 260px;
        margin-bottom: 0px;
        margin-right: 10px;
      }

      &__mes {
        width: 50%;
        margin-right: 5px;
      }

      &__ano {
        width: 50%;
        margin-left: 5px;
      }
    }

    &__mes {
      width: 145px;
      margin-right: 5px;
    }

    &__ano {
      width: 145px;
      margin-left: 5px;
    }
  }
}
</style>
