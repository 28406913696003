<template>
	<div class="sobrePrograma">
		<Header :headerGrande="false" :exibeAutorizadas="true" />

		<Breadcrumb :bold="true" paginaAtual="O Programa" />

		<div class="container-titulo">
			<h1 class="titulo-pagina">SOBRE O TEC CLUB</h1>
			<div v-if="showVideo" class="container-um">
				<div class="sobrePrograma__linha"></div>
				<div class="sobrePrograma__videoMobile">
					<Video :videoClicado="mostrarVideo" />
				</div>
				<div class="sobrePrograma__videoDesktop">
					<Video :videoClicado="mostrarVideo" />
				</div>

				<!-- <div>
					<div @click="clicouNoCarrousel" class="sobrePrograma__carrousel">
						<div class="sobrePrograma__carrouselMobile">
							<Carrousel />
						</div>
						<div class="sobrePrograma__carrouselDesktop mb-22">
							<Carrousel :carrouselClicado="mostrarCarrousel" />
						</div>
					</div>
					<div @click="clicouNoRegulamento" class="sobrePrograma__regulamento">
						
						<img
							class="regulamento"
							src="~@/assets/images/programa/regulamento.png"
						/>
						<div v-if="!regulamentoClicado">
							<img
								class="regulamentoDesk"
								src="~@/assets/images/programa/regulamentoDesk.png"
							/>
						</div>
					</div>
				</div> -->
			</div>

			<div v-if="mostrarCarrousel" class="container-um">
				<div class="sobrePrograma__linha"></div>
				<div class="sobrePrograma__carrouselMobile">
					<Carrousel :carrouselClicado="mostrarCarrousel" />
				</div>
				<div class="sobrePrograma__carrouselDesktop">
					<Carrousel :carrouselClicado="mostrarCarrousel" :mostrarCarrouselImagem="true" />
				</div>
				<div>
					<div @click="clicouNoRegulamento" class="sobrePrograma__carrousel sobrePrograma__regulamento mb-22">
						<img class="regulamento" src="~@/assets/images/programa/regulamento.png" />
						<div>
							<img class="regulamentoDesk" src="~@/assets/images/programa/regulamentoDesk.png" />
						</div>
					</div>
					<div @click="clicouNoVideo" class="sobrePrograma__regulamento">
						<img class="regulamento" src="~@/assets/images/programa/video.png" />
						<div>
							<img class="regulamentoDesk" src="~@/assets/images/programa/videoDesk.png" />
						</div>
					</div>
				</div>
			</div>

			<div v-if="mostrarRegulamento" class="container-um">
				<div class="sobrePrograma__linha"></div>
				<div class="sobrePrograma__carrouselMobile">
					<Regulamento />
				</div>
				<div class="sobrePrograma__carrouselDesktop">
					<Regulamento />
				</div>
				<div>
					<div @click="clicouNoVideo" class="sobrePrograma__carrousel sobrePrograma__regulamento mb-22">
						<img class="regulamento" src="~@/assets/images/programa/video.png" />
						<div>
							<img class="regulamentoDesk" src="~@/assets/images/programa/videoDesk.png" />
						</div>
					</div>
					<div @click="clicouNoCarrousel" class="sobrePrograma__regulamento">
						<img class="regulamento" src="~@/assets/images/programa/carrousel.png" />
						<div>
							<img class="regulamentoDesk" src="~@/assets/images/programa/carrouselDesk.png" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import { Header, Footer, Breadcrumb } from '@/components/elementos'
import Video from "@/components/elementos/programa/Video.vue";
import Carrousel from "@/components/elementos/programa/Carrousel.vue";
import Regulamento from "@/components/elementos/programa/Regulamento.vue";
import { mapState } from "vuex";
// import CarrouselPrograma from "@/components/elementos/programa/CarrouselPrograma.vue";
export default {
	name: "SobrePrograma",

	components: {
		Breadcrumb,
		Header,
		Footer,
		Video,
		Carrousel,
		Regulamento
		// CarrouselPrograma
	},

	computed: {
		...mapState({
			autorizaTelas: state => state.usuario.autorizaTelas
		}),
		showVideo() {
			return this.videoClicado;
		},
		mostrarCarrousel() {
			return this.carrouselClicado;
		},
		mostrarRegulamento() {
			return this.regulamentoClicado;
		}
	},

	data() {
		return {
			videoClicado: true,
			carrouselClicado: false,
			regulamentoClicado: false,
			mostrarVideo: false
		};
	},

	methods: {
		clicouNoVideo() {
			this.videoClicado = true;
			this.carrouselClicado = false;
			this.regulamentoClicado = false;
		},
		clicouNoCarrousel() {
			this.carrouselClicado = true;
			this.videoClicado = false;
			this.regulamentoClicado = false;
		},
		clicouNoRegulamento() {
			this.regulamentoClicado = true;
			this.carrouselClicado = false;
			this.videoClicado = false;
		}
	},
	created() {
		if (this.autorizaTelas == false || this.autorizaTelas == null) {
			this.$router.push("/");
			return
		}
	}
};
</script>

<style lang="scss" scoped>
.container-titulo {
	width: 1200px;
	margin: 50px auto 0 auto;

}
.sobrePrograma {
	margin-top: 80px;

	.mb-22 {
		@media (min-width: 768px) {
			margin-bottom: 22px;
		}
	}

	&__videoMobile {
		@media (min-width: 768px) {
			display: none;
		}
	}

	&__videoDesktop {
		display: none;

		@media (min-width: 768px) {
			display: block;
		}
	}

	&__carrouselMobile {
		@media (min-width: 768px) {
			display: none;
		}
	}

	&__carrouselDesktop {
		display: none;

		@media (min-width: 768px) {
			display: block;
		}
	}

	&__regulamento {
		cursor: pointer;

		.regulamento {
			@media (min-width: 768px) {
				display: none;
			}
		}

		.regulamentoDesk {
			display: none;

			@media (min-width: 768px) {
				display: block;
				width: 380px;
				height: 195px;
			}
		}
	}

	.container {
		@media (min-width: 768px) {
			width: 1200px;
			margin: 0 auto;
		}
	}

	.container-um {
		padding: 0 36px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;

		img {
			margin-bottom: 20px;
		}

		@media (min-width: 768px) {
			width: 1200px;
			margin: 0 auto;
			padding: 0;
			margin-top: 50px;

			flex-direction: row;
			justify-content: center;

			img {
				margin-bottom: 0px;
			}
		}
	}

	&__linha {
		border: 1px solid #bcbcbc;
		margin-top: 20px;
		margin-bottom: 30px;

		@media (min-width: 768px) {
			display: none;
		}
	}
}
</style>
