<template>
  <div class="video">
    <!-- <p>Lorem ispum</p> -->
    <div class="video__mobile">
      <img v-if="videoClicado" :class="{ 'video__clicado': videoClicado }" class="videoMobile" src="~@/assets/images/programa/video.png">
      <div>
        <iframe style="width: 100vw; max-width: 1200px;" height="315" src="https://www.youtube.com/embed/ldVsLrtJGGA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div class="video__desk">
      <img v-if="videoClicado" :class="{ 'video__clicado': videoClicado }" src="~@/assets/images/programa/videoDesk.png">
      <div>
        <iframe style="width: 100vw; max-width: 1200px;" height="315" src="https://www.youtube.com/embed/ldVsLrtJGGA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Video",

  props: {
    videoClicado: Boolean,
    // mobile: {
    //   type: Boolean,
    //   default: false,
    // }
  }
}
</script>

<style lang="scss" scoped>
  .video {
    width: 100%;
    max-width: 1280px;
    iframe {
      width: 302px;
      height: 170px;
      margin-bottom: 20px;
      @media(min-width: 768px) {
        width: 733px;
        height: 412px;
        margin-bottom: 0px;
      }
    }
    &__mobile {
      @media(min-width: 768px) {
        display: none;
      }
    }

    &__clicado {
      display: none;
    }

    &__desk {
      display: none;
      @media(min-width: 768px) {
        display: block;
      }
      cursor: pointer;
      img {
        width: 380px;
        height: 195px;
      }
    }
  }
</style>