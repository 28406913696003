<template>
  <div class="carrousel">
    <!-- <p>Lorem ispum</p> -->
    <div class="carrousel__mobile">
      <img v-if="mostrarThumb" :class="{ 'carrousel__clicado__mobile': carrouselClicado }" class="carrouselMobile" src="~@/assets/images/programa/carrousel.png">
      <div>
        <div v-if="carrouselClicado" class="carrousel">
          <CarrouselPrograma />
        </div>
      </div>
    </div>
    <div class="carrousel__desk">
      <img v-if="mostrarThumb" :class="{ 'carrousel__clicado': carrouselClicado }" src="~@/assets/images/programa/carrouselDesk.png">
      <div v-if="carrouselClicado">
        <div class="carrousel">
          <CarrouselPrograma />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarrouselPrograma from "@/components/elementos/programa/CarrouselPrograma.vue";
export default {
  name: "Carrousel",

  props: {
    carrouselClicado: Boolean,
    mostrarThumb: {
      type: Boolean,
      default: true,
    },
    mostrarCarrouselImagem: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CarrouselPrograma
  }
}
</script>

<style lang="scss" scoped>
  .carrousel {
    .carrousel {
      width: 100%;
      @media(min-width: 768px) {
        width: 733px;
        height: 412px;
      }
    }
    &__mobile {
      img {
        margin-bottom: 20px;
      }
      @media(min-width: 768px) {
        display: none;
      }
    }

    &__clicado {
      display: none;
      @media(max-width: 768px) {
        // display: block;
      }

      &__mobile {
        width: 302px;
        height: 0px;
      }
    }

    &__desk {
      display: none;
      @media(min-width: 768px) {
        display: block;
      }
      cursor: pointer;
      img {
        width: 380px;
        height: 195px;
      }
    }
  }
</style>